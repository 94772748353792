import React, {useState, useEffect} from 'react';
import Layout from '../components/layout';
import LanguageContext from "../context/LanguageContext";
import Hero from '../components/About/Hero/Hero';
import MainContent from '../components/About/Content/MainContent/MainContent';
import OurTeam from '../components/About/Content/OurTeam/OurTeam';
import Resources from '../components/About/Content/Resources/Resources';
import Seo from '../components/seo';
import {Box} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const AboutPage = ({pageContext}) => {

  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
      setHasMounted(true);
  }, []);

  if(!hasMounted) return <div style={{ alignItems: "center", display: "flex", justifyContent: "center", height: "100vh", width: "100vw" }}><CircularProgress /></div>;

    return (
    <LanguageContext.Consumer>
    {language => (
    <Layout>
       <Seo title={language.english ? "About" : "Fúinn"} lang={language.english ? "en" : 'ga'} />
       <Hero language={language} pageContext={pageContext} />
       <Box role="main">
       <MainContent language={language} pageContext={pageContext} />
       <OurTeam language={language} pageContext={pageContext}/>
       <Resources language={language} pageContext={pageContext} />
       </Box>
    </Layout>
    )}
    </LanguageContext.Consumer>
  );
}

export default AboutPage;