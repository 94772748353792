
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    innerA:{
        transition: 'all 0.5s ease',
        color: 'inherit',
        textDecoration: 'none',
        borderBottom: '1px solid currentColor',
     
      "&:hover":{
        background: '#FFCE3B',
      }
    }
});

export default useStyles;