import React, { useState, useEffect, useRef } from 'react';
import { Typography, Box, TabScrollButton, Divider, Container, Grid, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import useStyles from './styles';
import clsx from 'clsx';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link, navigate } from "gatsby";


const Hero = ({pageContext, language}) => {

    const isInitialMount = useRef(true);

    const social = () => {
      navigate(language.english ? '/social-enterprise-policies' : '/polasaithe-fiontraiochta-soisialta')
    }

    useEffect(() => {   
      if (isInitialMount.current) {
        isInitialMount.current = false;
     } else {
      if(language.english){
        navigate("/about")
       }else{
        navigate("/fuinn")
       }
     }
    },[language.english]);

    const theme = useTheme();
    const classes = useStyles();
    const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));

    const color1 = pageContext?.heroBackgroundColour;
    const topColorPercent = matchesSm ? '100%' :'100%';
    const bottomColorPercent = matchesSm ? '0%' :'0%';

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const scrollButton = (props) => {
        return (
        props.direction === "left" ?
        <TabScrollButton orientation="horizontal" direction="left" sx={{color:'white'}} onClick={props.onClick}>
            <ArrowBackIcon  />
        </TabScrollButton> 
        :
        <TabScrollButton orientation="horizontal" direction="right" sx={{color:'white'}} onClick={props.onClick}>
            <ArrowForwardIcon  />
        </TabScrollButton> 
        );
      };

    return (
        <Grid container style={{background: `linear-gradient(to bottom,  ${color1} ${topColorPercent},#FFFFFF ${bottomColorPercent})`}} role="banner" aria-label="hero banner">
            <Container maxWidth="xl">
                <Grid item container pt={matchesSm ? 2 : 8} pb={14} pl={matchesSm ? undefined : 4}>
                <Grid item xs={12} pt={matchesSm ? 6 : undefined}>
                    <Typography variant="heading" style={{fontSize:'56px', lineHeight:'64px'}} color="white" component="h1">{language.english ? 'About' : 'Fúinn'}</Typography>
                </Grid>
                    <Grid item xs={12}>                   
                      <Grid container pt={1}>
                        <Grid item xs={12} sm={2} pt={1.7}>
                        <Typography color={pageContext.heroFontColorBlack === true ? "black" : "white"}>
                            {language.english ? 'On this page:' : 'Ar an leathanach seo:'}
                        </Typography>
                        </Grid>
                          <Grid item sm={8} xs={12}>
                            {matchesMd ?
                            <Box sx={{ maxWidth: { xs: 380, sm: 480 }}}>
                                <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons
                                indicatorColor="primary"
                                textColor="primary"
                                ScrollButtonComponent={scrollButton}
                                aria-label="scrollable tabs" 
                                >
                                <Tab sx={{textTransform: 'none'}} label={<Link id="main" to="/social-enterprise-policies" className={classes.anchor}><Typography color={pageContext.heroFontColorBlack === true ? "black" : "#FFE69D"} variant="primary2">{language.english ? 'Social Enterprise Policies' : 'Beartais Fiontair Shóisialta'}</Typography></Link>} />
                                <Tab sx={{textTransform: 'none'}} label={<AnchorLink onAnchorLinkClick={() => document.getElementById('whatissocial').focus()} className={classes.anchor} to={language.english ? `/about/#whatissocial` : `/fuinn/#whatissocial`}><Typography color={pageContext.heroFontColorBlack === true ? "black" : "#FFE69D"} variant="primary2">{language.english ? 'What is a social enterprise?' : 'Cad is fiontar sóisialta ann?'}</Typography></AnchorLink>} />
                                <Tab sx={{textTransform: 'none'}} label={<AnchorLink onAnchorLinkClick={() => document.getElementById('ourteam').focus()} className={classes.anchor} to={language.english ? `/about/#ourteam` : `/fuinn/#ourteam`}><Typography color={pageContext.heroFontColorBlack === true ? "black" : "#FFE69D"} variant="primary2">{language.english ? 'Our Team' : 'Ár bhFoireann'}</Typography></AnchorLink>} />
                                <Tab sx={{textTransform: 'none'}} label={<AnchorLink onAnchorLinkClick={() => document.getElementById('resources').focus()} className={classes.anchor} to={language.english ? `/about/#resources` : `/fuinn/#resources`}><Typography color={pageContext.heroFontColorBlack === true ? "black" : "#FFE69D"} variant="primary2">{language.english ? 'Resources' : 'Acmhainní'}</Typography></AnchorLink>} />
                                </Tabs>
                            </Box> :
                            <Box display="flex" mt={1}>
                                <Box display="flex">
                                    <Box display="flex" mr={1} p={1} className={clsx(classes.anchorlink, { [classes.anchorlinkBlack]: pageContext.heroFontColorBlack === true })}>
                                    <Link id="main" to="/social-enterprise-policies" className={classes.anchor}><Typography variant="primary2" color={pageContext.heroFontColorBlack === true ? "black" : "#FFE69D"}>{language.english ? 'Social Enterprise Policies' : 'Beartais Fiontair Shóisialta'}</Typography></Link>
                                   <Box pt={0.2}>
                                    <ArrowForwardIosIcon style={{color:"#FFE69D", height:'14px'}}/>
                                   </Box>
                                    </Box>
                                    <Divider orientation="vertical" flexItem sx={{background:pageContext.heroFontColorBlack === true ? "black" : "#FFE69D"}} />
                                </Box>
                                <Box display="flex">
                                <AnchorLink onAnchorLinkClick={() => document.getElementById('whatissocial').focus()} to={language.english ? `/about/#whatissocial` : `/fuinn/#whatissocial`} className={classes.anchor}>
                                    <Box display="flex" ml={1} mr={1} p={1} className={clsx(classes.anchorlink, { [classes.anchorlinkBlack]: pageContext.heroFontColorBlack === true })}>
                                    <Typography variant="primary2" color={pageContext.heroFontColorBlack === true ? "black" : "#FFE69D"}>{language.english ? 'What is a social enterprise?' : 'Cad is fiontar sóisialta ann?'}</Typography>
                                    <ArrowDownwardIcon style={{color:"#FFE69D"}}/>
                                    </Box>
                                </AnchorLink>
                                    <Divider orientation="vertical" flexItem sx={{background:pageContext.heroFontColorBlack ? "black" : "#FFE69D"}} />
                                </Box>
                                <Box display="flex">
                                <AnchorLink onAnchorLinkClick={() => document.getElementById('ourteam').focus()} to={language.english ? `/about/#ourteam` : `/fuinn/#ourteam`} className={classes.anchor}>
                                    <Box display="flex" ml={1} mr={1} p={1} className={clsx(classes.anchorlink, { [classes.anchorlinkBlack]: pageContext.heroFontColorBlack === true })}>
                                    <Typography variant="primary2" color={pageContext.heroFontColorBlack === true ? "black" : "#FFE69D"}>{language.english ? 'Our Team' : 'Ár bhFoireann'}</Typography>
                                    <ArrowDownwardIcon style={{color:"#FFE69D"}}/>
                                    </Box>
                                </AnchorLink>
                                    <Divider orientation="vertical" flexItem sx={{background:pageContext.heroFontColorBlack ? "black" : "#FFE69D"}} />
                                </Box>
                                <Box display="flex">
                                <AnchorLink onAnchorLinkClick={() => document.getElementById('resources').focus()} to={language.english ? `/about/#resources` : `/fuinn/#resources`} className={classes.anchor}>
                                    <Box display="flex" ml={1} mr={1} p={1} className={clsx(classes.anchorlink, { [classes.anchorlinkBlack]: pageContext?.heroFontColorBlack === true })} style={{'&:hover': {border: "1px solid black!important",}}}>
                                   <Typography variant="primary2" color={pageContext.heroFontColorBlack === true ? "black" : "#FFE69D"}>{language.english ? 'Resources' : 'Acmhainní'}</Typography>
                                   <ArrowDownwardIcon style={{color:"#FFE69D"}}/>
                                    </Box>
                                </AnchorLink>
                                </Box>
                            </Box>}
                          </Grid>
                          <Grid item  sm={12} md={8} lg={8} xl={8} mt={1.6}>
                              <Typography style={{fontSize: matchesSm ? "20px" : "24px", lineHeight: matchesSm ? '28px' : '32px', fontWeight: 400}} color="white" dangerouslySetInnerHTML={{__html:  language.english ? pageContext?.intro : pageContext?.introIrish}} />
                            <Box mt={1.75}>
                              <Button id="about" aria-label={language.english ? 'More about social enterprises policies' : 'Tuilleadh faoi bheartais fiontair shóisialta'} variant="contained" className={classes.btn} size="small" style={{textTransform:'none', width:matchesSm ? '75%': undefined}} onClick={() => social()}>
                                <Typography variant="tag" fontSize={matchesMd ? '16px' :  '16px'}>{language.english ? 'More about social enterprises policies' : 'Tuilleadh faoi bheartais fiontair shóisialta'}</Typography>
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
)}

export default Hero;