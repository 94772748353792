import React from 'react';
import { Typography, Container, Grid, Box } from '@mui/material';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useStyles from './styles';

const AboutOurTeam = ({pageContext, language}) => {

    const classes = useStyles();

    const theme = useTheme();
    const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));
    const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));

    const image1 = getImage(pageContext.contactCard[0].image.localFile.childImageSharp);
    const image2 = getImage(pageContext.contactCard[1].image.localFile.childImageSharp);

    return (
        <Grid container style={{backgroundColor:'#E6EDEC'}} aria-label="Our Team">
        <Container maxWidth="xl">
            <Grid container display="flex" mt={5} mb={5}>
                <Grid item xs={12} lg={4} p={3}>
                    <Typography variant="bodyheading" id="ourteam" component="h2" tabIndex={"0"} gutterBottom>{language.english ? 'Our Team' : 'Ár bhFoireann'}</Typography>
                  
                    <Typography variant="bodysmall" dangerouslySetInnerHTML={{__html:  language.english ? pageContext.ourteam : pageContext.ourteamIrish}} />
                </Grid>
                <Grid item md={6} lg={4} p={3} display="flex" justifyContent={matchesLg ? undefined : "center" }>
                    <Box style={{backgroundColor:'white', borderRadius:15, width:matchesSm ? '100%' : '80%'}} p={4}>
                        <Box mt={2}>
                            <GatsbyImage style={{marginBottom:'10px', borderRadius: '50%', width:'125px', height:'125px'}} image={getImage(image1)} alt={language.english ? pageContext.contactCard[0].image.altText : pageContext.contactCard[0].image.altTextIrish.irishAlt} />
                        </Box>
                       
                        <Typography component="h3" variant="cardheading" fontSize="24px" gutterBottom style={{display:'block'}}>{pageContext.contactCard[0].name}</Typography>
                      
                        <Typography variant="bodysmall" style={{display:'block'}}>
                        {language.english ? pageContext.contactCard[0].text : pageContext.contactCard[0].textIrish}{' '}<a className={classes.innerA} href={`mailto:${pageContext.contactCard[0].email}`}>{pageContext.contactCard[0].email} </a>
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={6} lg={4} p={3}>
                    <Box style={{backgroundColor:'white', borderRadius:15, width:matchesSm ? '100%' : '80%'}} p={4}>
                        <Box mt={2}>
                            <GatsbyImage style={{marginBottom:'10px', borderRadius: '50%', width: '125px', height:'125px', color:'red'}} image={getImage(image2)} alt={language.english ? pageContext.contactCard[1].image.altText : pageContext.contactCard[1].image.altTextIrish.irishAlt}/>
                        </Box>
                       
                        <Typography component="h3" variant="cardheading" gutterBottom style={{display:'block'}}>{pageContext.contactCard[1].name}</Typography>
                     
                        <Typography variant="bodysmall" style={{display:'block'}}>
                        {language.english ? pageContext.contactCard[1].text : pageContext.contactCard[1].textIrish}{' '}<a className={classes.innerA} href={`mailto:${pageContext.contactCard[1].email}`}>{pageContext.contactCard[1].email} </a>
                        </Typography>

                    </Box>
                </Grid>
            </Grid>
        </Container>
    </Grid>
    )
}

export default AboutOurTeam;