import React from 'react';
import { Typography, Container, Grid, Box, Avatar } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import useStyles from './styles';
import DownloadIcon from '@mui/icons-material/Download';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const AboutResources = ({pageContext, language}) => {

    const classes = useStyles();
    const theme = useTheme();
    const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Container maxWidth="xl" aria-label="Resources">
                <Grid container>
                    <Grid item xs={12} mt={4}>
                        <Typography tabIndex={"0"} variant="bodyheading" id="resources" component="h2">{language.english ? 'Resources' : 'Acmhainní'}</Typography>
                    </Grid>
                    {pageContext.resources.map((item, index) => (

                    <Grid key={index} item xs={12} md={4} pt={matchesSm ? 0.5 : 4} pb={matchesSm ? 0.5 : 4} pl={matchesSm ? 0.5 : 4}>

                    {item.resourceIsFile === true ? 
                    
                    <a style={{textDecoration:'none'}} target="_blank" rel={`noopener noreferrer`} aria-label={`${language.english ? item.title : item.titleIrish}, opens in new tab`} noopener noreferrer href={item.file.mediaItemUrl}>
                        <Card className={classes.card}>
                            <Box pl={1} pr={1} pb={0.5}>
                            <CardContent>
                                <Box display="flex">
                                <Avatar style={{backgroundColor:'#2C2C69'}}>
                                    <DownloadIcon />
                                </Avatar>
                                <Box ml={2}>
                                    <Typography gutterBottom variant="primary" component="h3">       
                                    {language.english ? item.title : item.titleIrish}
                                    </Typography>
                                </Box>
                                </Box>
                            </CardContent>
                            </Box>
                        </Card>
                    </a>
                
                    : 
                    
                    <a style={{textDecoration:'none'}} target="_blank" aria-label={`${language.english ? item.title : item.titleIrish}, opens in new tab`} rel="noopener noreferrer" href={item.url}>
                        <Card className={classes.card}>
                            <Box pl={1} pr={1} pb={0.5}>
                            <CardContent>
                                <Box display="flex">
                                <Avatar style={{backgroundColor:'#2C2C69'}}>
                                    <PlayArrowIcon />
                                </Avatar>
                                <Box ml={2}>
                                    <Typography gutterBottom variant="primary" component="h3">       
                                    {language.english ? item.title : item.titleIrish}
                                    </Typography>
                                </Box>
                                </Box>
                            </CardContent>
                            </Box>
                        </Card>
                      
                    </a>
                    }
                        
                    </Grid>

                    ))}
                </Grid>
        </Container>
    )
}

export default AboutResources;