import React from 'react';
import { Typography, Container, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import "./mainContent.css";

const AboutMainContent = ({pageContext, language}) => {

    const theme = useTheme();
    const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Grid container aria-label="Main content">
        <Container maxWidth="md">
            <Grid item xs={12} mt={10} mb={12} ml={matchesSm ? 4 : undefined} mr={matchesSm ? 4 : undefined}>
                <Typography variant="bodyheadinglarge" style={{fontSize: matchesSm ? '28px' : '40px', lineHeight:matchesSm ? '32px' : '48px'}} id="whatissocial" tabIndex={"0"} component="h2">{language.english ? 'What is a Social Enterprise?' : 'Cad is Fiontar Sóisialta ann?'}</Typography>
                <br />
                <Typography style={{fontSize:matchesSm ? '18px' : '21px', lineHeight:'140%'}} dangerouslySetInnerHTML={{__html:  language.english ? pageContext.content : pageContext.contentIrish}} />
            </Grid>
        </Container>
        </Grid>
    )
}

export default AboutMainContent;